import { IncomeRules } from '@/assets/icons/comm/IncomeRules';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import BaseTopNav from '@/components/base/baseTopNav';
import { DisplayUserAgreement } from '@/components/display/displayUserAgreement';
import ValueDisplay from '@/components/display/displayValueDisplay';
import NumberInput from '@/components/features/featuresNumberInput';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { GetTextEnum } from '@/enums/businessEnum';
import { toast } from '@/layouts/components/ToastMessage';
import { buyAIApi, getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { cn } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Checkbox, Divider, ScrollShadow } from '@nextui-org/react';
import { useLocation, useModel } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// 理财列表
enum FINANCIAL_MANAGEMENT_LIST_ENUM {
  // 量化理财
  QUANTIFICATION_FINANCIAL_MANAGEMENT = '0',
  // 定期理财
  REGULAR_FINANCIAL_MANAGEMENT = '1',
}
export default () => {
  const { t } = useTranslation();
  const { state = {} }: any = useLocation();
  const [num, setNum] = useState(0);
  //
  const [urlState] = useUrlState();
  //
  const { setCurrentTab }: any = useModel('mine.aItrade.model') || {};
  //
  const { joinSymbol } = useModel('exchange');

  // 每手金额
  const handNum = useMemo(() => state?.handNum ?? 1, []);

  const [time] = useState(new Date().getTime());

  const [checked, setChecked] = useState(true);
  const { openPrompt } = useGlobalPrompt();
  const { refreshWallet } = useModel('user');
  const { upDownColorConfig, isPc } = useModel('system');
  // 红涨绿跌设置
  const { currentUpClass, currentDownClass } = useModel('system');
  // 设置颜色
  const handleChangeColor = (value: any) => {
    if (Number(value) > 0) {
      // 涨
      return upDownColorConfig === 'upGreen' ? '#2db880' : '#ee4456';
    } else {
      //跌
      return upDownColorConfig === 'upGreen' ? '#ee4456' : '#2db880';
    }
  };

  // 计算出来等于多少 Usd
  const amount = useMemo(() => num * handNum, [num, handNum]);
  // 是否是量化
  const isQuantification = useMemo(() => {
    return (
      +urlState?.financeType ===
      +FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT
    );
  }, []);
  // 预计收益
  const expectedRevenue = useMemo(() => {
    if (isQuantification)
      return [
        (amount * state?.displayMinProfitRate) / 100,
        (amount * state?.displayMaxProfitRate) / 100,
      ];
    else return (num * state?.displayMaxProfitRate) / 100;
  }, [num]);
  // 渲染列表
  const renderInfo = [
    isQuantification
      ? {
          label: t('每手金额'),
          value: (
            <div>
              {handNum}
              <span className="ml-1">USDT</span>
            </div>
          ),
        }
      : null,
    {
      label: t('生效日期'),
      value: RenderUtil.formatDate(time, 'YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: t('到期日期'),
      value: RenderUtil.formatDate(
        time + (state?.expirationDate ?? 0) * 3600 * 24 * 1000,
        'YYYY-MM-DD HH:mm:ss',
      ),
    },
    {
      label: t('最大投资金额'),
      value: !isQuantification
        ? RenderUtil.FormatAmount(state?.maximumInvestment, 2, false) +
          state?.coinName
        : RenderUtil.FormatAmount(
            state?.maximumInvestment / handNum,
            2,
            false,
          ) + t('手'),
    },
    isQuantification
      ? {
          label: t('服务费'),
          value: state?.settlementFee
            ? RenderUtil.FormatAmount(state?.settlementFee, 2, false) + '%'
            : `--`,
        }
      : null,
    isQuantification
      ? {
          label: t('最大回撤'),
          value: state?.maxDrawdown
            ? RenderUtil.FormatAmount(state?.maxDrawdown, 2, false) + '%'
            : `--`,
        }
      : null,
    {
      label: t('收益率'),
      value: (
        <>
          {isQuantification && (
            <>
              <span>
                {RenderUtil.FormatAmount(state?.displayMinWinRate, 2, false)}%
              </span>
              <span>~</span>
            </>
          )}
          <span>
            {RenderUtil.FormatAmount(state?.displayMaxWinRate, 2, false)}%
          </span>
        </>
      ),
    },
    {
      label: t('预计收益'),
      value: (
        <>
          {isQuantification && Array.isArray(expectedRevenue) ? (
            <>
              <div
                className="w-fit break-all font-[600]"
                style={{ color: handleChangeColor(expectedRevenue[0]) }}
              >
                {num
                  ? RenderUtil.FormatAmount(expectedRevenue[0], 2, false) +
                    '~' +
                    RenderUtil.FormatAmount(expectedRevenue[1], 2, false)
                  : '--'}
                {state?.coinName}
              </div>
            </>
          ) : (
            <ValueDisplay
              value={
                num && !Array.isArray(expectedRevenue)
                  ? RenderUtil.FormatAmount(expectedRevenue, 2, false)
                      ?.split(',')
                      ?.join('')
                  : '--'
              }
              len={8}
              suffix={state?.coinName}
              justify="justify-start"
            />
          )}
        </>
      ),
      //  : (
      //   `--${state?.coinName}`
      // ),
    },
    // {
    //   label: t('最小购买金额'),coinName
    //   value: (
    //     <div>
    //       {state?.minimumInvestment}
    //       <span className="ml-1">USDT</span>
    //     </div>
    //   ),
    // },
    // {
    //   label: t('生效日期'),
    //   value: RenderUtil.formatDate(time),
    // },
  ].filter((item) => item);
  const userAgreement = useMemo(() => {
    if (isQuantification) {
      return GetTextEnum.QUANTITATIVE_SERVICE_CONTRACT;
    } else {
      return GetTextEnum.SERVICE_CONTRACT;
    }
  }, []);
  // 协议 量化收益
  // const { data: userAgreement = {} } = useReq(
  //   () =>
  //     getPrivacyAgreementApi({
  //       type: isQuantification?GetTextEnum.QUANTITATIVE_SERVICE_CONTRACT:GetTextEnum.SERVICE_CONTRACT,
  //       noMsg: true,
  //     }),
  //   {},
  // );
  // 规则
  const { data: userAgreementRule } = useReq(
    () =>
      getPrivacyAgreementApi({
        type: isQuantification
          ? GetTextEnum.QUANTITATIVE_EARNINGS_RULES
          : GetTextEnum.EARNINGS_RULES,
        noMsg: true,
      }),
    {},
  );
  // 时间线
  const { data: userAgreementTimeline } = useReq(
    () =>
      getPrivacyAgreementApi({
        type: isQuantification
          ? GetTextEnum.QUANTITATIVE_FINANCIAL_TIMELINE
          : GetTextEnum.FINANCIAL_TIMELINE,
        noMsg: true,
      }),
    {},
  );

  const { coinsFunds } = useModel('user');

  // 现货ustd钱包 可用余额
  const usdtAvailable = useMemo(() => {
    return (
      coinsFunds?.walletList?.find((item: any) => item.name === 'USDT')
        ?.availableBalance ?? 0
    );
  }, [coinsFunds]);
  //购买AI产品
  const { run: buyAIReq, loading: submitLoading } = useReq(buyAIApi, {
    manual: true,
    loadingDefault: false,
    onSuccess() {
      toast.success(t('购买成功')); //
      refreshWallet();
      history.back();
      setCurrentTab(urlState?.financeType);
    },
  });

  // 最大可购买手数
  const maxLot = useMemo(() => {
    return handNum ? Math.floor(usdtAvailable / handNum) : handNum; // 可购买手数
  }, [usdtAvailable, handNum]);
  // 定期金额
  const periodicAmount = useMemo(() => {
    return coinsFunds?.walletList?.find(
      (item: any) => item.name === state?.coinName,
    )?.availableBalance;
  }, [num, state?.amount]);
  // 最大数值操作进行填充
  const handleNumChange = () => {
    // 量化
    if (isQuantification) {
      if(periodicAmount<maxLot){
        return setNum(periodicAmount);
      }
      return setNum(maxLot);
    }
    // 定期
    if (!isQuantification) {
      const BTCAvailableBalance = coinsFunds?.walletList?.find(
        (item: any) => item.name === state?.coinName,
      )?.availableBalance;
      return setNum(BTCAvailableBalance);
    }
  };
  const icon = useMemo(() => {
    if (isQuantification) {
      return state?.currencyRespDTOS?.icon1
        ? state?.currencyRespDTOS?.icon1
        : state?.currencyRespDTOS?.icon2;
    } else {
      return state?.icon;
    }
  }, [isQuantification]);
  // 名称
  const name = useMemo(() => {
    // ai理财
    if (isQuantification) {
      return isQuantification && state?.name
        ? state?.name
        : state?.currencyRespDTOS?.symbol;
    } else {
      //  定期理财
      return !isQuantification && state?.name ? state?.name : state?.coinName;
    }
  }, [isQuantification]);
  return (
    <div>
      <BaseTopNav title={isQuantification ? t('量化理财') : t('定期理财')} />
      <div className="px-4">
        <div className="flex justify-between items-center border-1 border-borderColor rounded-md py-[12px] px-[16px] mb-[24px]">
          <div className="flex items-center gap-2">
            {/* <img
              src={icon}
              className="w-[32px] h-[32px] rounded-full object-cover"
              alt=""
            /> */}
            <div className="text-base text-backContrastColor">{name}</div>
            {/* 做多 做空 杠杆 */}
            {isQuantification && (
              <div>
                {!state?.direction && (
                  <>
                    <span
                      className={cn(
                        `!text-auxiliaryTextColor !bg-auxiliaryTextColor/10 h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                      )}
                    >
                      {t('中性')}
                    </span>
                  </>
                )}
                {state?.direction === 1 && (
                  <span
                    className={cn(
                      currentUpClass,
                      `bg-successColor !text-[#fff] h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                    )}
                  >
                    {t('做多')}
                  </span>
                )}
                {isQuantification && state?.direction === 2 && (
                  <span
                    className={cn(
                      currentDownClass,
                      `bg-errorColor !text-[#fff] h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                    )}
                  >
                    {t('做空')}
                  </span>
                )}
                <span
                  className={cn(
                    `ml-[6px] !text-auxiliaryTextColor !bg-auxiliaryTextColor/10 h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                  )}
                >
                  10x
                </span>
              </div>
            )}
          </div>
          <div className="text-backContrastColor text-base">
            {t('{{date}}天', { date: state?.expirationDate })}
          </div>
        </div>
        <div>
          <div className="flex justify-between text-sm mb-1">
            <span className="leading-[22px]">
              {isQuantification ? t('手数') : t('金额')}
            </span>
            {/* 量化 */}
            {isQuantification && (
              <span className="text-auxiliaryTextColor">
                {t('可用{{num}}手', {
                  num: RenderUtil.FormatAmount(maxLot),
                })}
              </span>
            )}
            {/* 定期 */}
            {!isQuantification && (
              <span className="text-auxiliaryTextColor">
                {t('可用{{num}}{{coinName}}', {
                  num: RenderUtil.FormatAmount(periodicAmount, 2, false),
                  coinName: state?.coinName,
                })}
              </span>
            )}
          </div>
          <div
            className={cn(
              'flex px-2 justify-between h-[48px] items-center rounded-md overflow-hidden bg-backgroundAuxiliaryColor pr-2',
              {
                'border-1 border-errorColor': false,
              },
            )}
          >
            <NumberInput
              className=" overflow-hidden"
              value={num}
              onChange={setNum}
              placeholder={
                isQuantification
                  ? t('{{min}}手最小起投', {
                      min: Math.floor(state?.minimumInvestment / handNum),
                    })
                  : t('{{min}}最小起投', {
                      min: state?.minimumInvestment,
                    })
              }
              classNames={{
                base: 'rounded-[3px] overflow-hidden h-[40px]',
                inputWrapper:
                  'h-full border-none rounded-[3px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
              }}
            />
            <div className="flex items-center gap-1 flex-shrink-0 py-2">
              <div className="h-[18px] mx-2 w-[1px] bg-backContrastColor/10"></div>
              <div
                className="text-base text-primary px-3"
                onClick={() => handleNumChange()}
              >
                {t('最大')}
              </div>
            </div>
          </div>
          {isQuantification && (
            <div className="flex text-xs mb-[2px] pt-2">
              <span>=</span>
              <div className="flex items-center gap-1">
                <span className="ml-1">{RenderUtil.FormatAmount(amount)}</span>
                <span>{state?.coinName}</span>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 mt-[24px]">
          {renderInfo?.map((item) => {
            return (
              <div
                key={item?.label}
                className="text-sm flex justify-between items-center"
              >
                <div className="text-auxiliaryTextColor">{item?.label}</div>
                <div className="max-w-[50%]">{item?.value}</div>
              </div>
            );
          })}
        </div>

        {/* 描述 */}
        <Divider className="!bg-backgroundAuxiliaryColor my-4" />
        <div className="flex flex-col gap-2">
          <div
            className="flex items-center gap-2 text-sm text-auxiliaryTextColor"
            onClick={() => {
              openPrompt({
                title: t('收益规则'),
                content: (
                  <ScrollShadow hideScrollBar className="max-h-[50vh] ">
                    <FeaturesRenderText
                      className="whitespace-pre-wrap"
                      data={userAgreementRule}
                    />
                  </ScrollShadow>
                ),
                isShowConfirm: false,
                classNames: {
                  body: '!px-4 !pb-[30px] overflow-auto max-h-[50vh]',
                  content:
                    'w-fit prose prose-zinc max-w-none dark:prose-invert bg-background w-full',
                  title: '!justify-start text-[18px] pt-[8px]  font-semibold',
                },
                modalProps: {
                  placement: 'bottom',
                },
              });
            }}
          >
            <IncomeRules />
            <span className="border-b-1 border-b-borderColor border-dashed">
              {t('收益规则')}
            </span>
          </div>
          {/* <div
            className="flex items-center gap-2 text-sm text-auxiliaryTextColor"
            onClick={() => {
              openPrompt({
                title: isQuantification
                  ? t('量化理财时间线')
                  : t('定期理财时间线'),
                content: (
                  <ScrollShadow hideScrollBar className="max-h-[50vh] ">
                    <FeaturesRenderText data={userAgreementTimeline} />
                  </ScrollShadow>
                ),
                isShowConfirm: false,
                classNames: {
                  body: '!px-4 !pb-[30px]',
                  content:
                    'w-fit prose prose-zinc max-w-none dark:prose-invert bg-background w-full',
                  title: '!justify-start text-[18px] pt-[8px]  font-semibold',
                },
                modalProps: {
                  placement: 'bottom',
                },
              });
            }}
          >
            <Quantify />
            <span className="border-b-1 border-b-borderColor border-dashed">
              {isQuantification ? t('量化理财时间线') : t('定期理财时间线')}
            </span>
          </div> */}
        </div>
      </div>

      {/* 购买按钮固定 */}
      <div
        className={`mt-[40%] w-full px-4 ${!isPc ? 'fixed bottom-[16px]' : ''}`}
      >
        <div className="flex items-center gap-2 mb-3 px-2">
          <Checkbox
            size="sm"
            defaultSelected
            color="default"
            isSelected={checked}
            onValueChange={setChecked}
            className="p-0 "
          >
            <span className={cn(`mr-0.5 text-[12px]`)}>
              {t('我已详细阅读并同意')}
            </span>
          </Checkbox>

          <DisplayUserAgreement
            title={t('服务协议')}
            requestKey={userAgreement}
          >
            {/* // SYSTEM_SERVICE_AGREEMENT */}
            <span className="inline-block ml-1.5 text-darkBrandColor text-[12px] cursor-pointer">
              {`${t('服务协议')}`}
            </span>
          </DisplayUserAgreement>
        </div>

        <Button
          className="bg-primary  w-full text-[#fff]  !h-[40px] !rounded-md"
          isLoading={submitLoading}
          // isDisabled={!checked}
          spinner={<LoadingSvg />}
          onClick={() => {
            if (!checked) {
              return toast.error(t('请勾选协议'));
            }

            if (isNaN(amount) || amount <= 0) {
              return toast.error(t('请输入正确的数量'));
            }
            // if (!isQuantification && num < state?.minimumInvestment) {
            // }
            buyAIReq({
              configId: state?.id,
              amount: isQuantification ? amount : num,
            });
          }}
        >
          {t('购买')}
        </Button>
      </div>
    </div>
  );
};
